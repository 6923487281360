import './SettingsTranslationsContent.less'

import { Button, Checkbox, Form, Popover } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  FetchTranslationsOptions,
  LanguageTranslations,
  addLanguage,
  changeTranslation,
  chooseDefaultLanguage,
  deleteLanguage,
  fetchTranslations as fetchTranslationsRequest
} from '../../../requests'
import { enumToOptions } from '../../../utils'
import { DatacIcon, DatacIconButton, DatacLoading, DatacMessage, DatacSubtitle, DatacTitle } from '../../common'
import {
  TranslationsLanguageSelect,
  TranslationsLists,
  TranslationsNoResults,
  onChangeTranslationProps
} from '../../shared/TranslationsLists'
import { SettingsLayout } from '../SettingsLayout'

enum FilterOptions {
  SubjectDatabase = 'subject_database',
  PrivacySettings = 'privacy_settings'
}

interface TranslationsFiltersProps {
  setFilterState: (filterState: FetchTranslationsOptions) => void
}
const TranslationsFilters: React.FC<TranslationsFiltersProps> = ({ setFilterState }) => {
  const intlTranslations = useScopedIntl('studies.translations')
  const [formInstance] = Form.useForm()
  const [isOpened, setIsOpened] = useState(false)

  const onSubmit = () => formInstance.submit()

  const onClear = () => formInstance.resetFields()

  const onFormFinish = ({ values }: { values: string[] }) => {
    const allSelected = !values?.length
    setIsOpened(false)
    setFilterState({
      subjectDatabase: allSelected || !!values.find(f => f === FilterOptions.SubjectDatabase),
      privacySettings: allSelected || !!values.find(f => f === FilterOptions.PrivacySettings)
    })
  }

  const popoverContent = (
    <div className="settings-translations-content__filters">
      <Form onFinish={onFormFinish} form={formInstance}>
        <Form.Item className="settings-translations-content__filters__options" name="values">
          <Checkbox.Group options={enumToOptions(FilterOptions, intlTranslations)} />
        </Form.Item>
      </Form>
      <div className="settings-translations-content__filters__controls">
        <Button size="middle" type="default" onClick={onClear}>
          {intlTranslations('filters.clear')}
        </Button>
        <Button size="middle" type="primary" onClick={onSubmit}>
          {intlTranslations('filters.save')}
        </Button>
      </div>
    </div>
  )

  return (
    <Popover content={popoverContent} trigger="click" placement="bottomLeft" open={isOpened} onOpenChange={setIsOpened}>
      <DatacIcon name="filters" type="blue" size="normal" />
    </Popover>
  )
}

export const SettingsTranslationsContent: React.FC = () => {
  const intlTranslations = useScopedIntl('studies.translations')
  const [translations, setTranslations] = useState<LanguageTranslations[]>([])
  const [isFetchingTranslations, setIsFetchingTranslations] = useState(true)
  const [isNewLanguageModalOpened, setIsNewLanguageModalOpened] = useState(false)
  const [isNewDefaultLanguageModalOpened, setIsDefaultLanguageModalOpened] = useState(false)
  const [filterState, setFilterState] = useState<FetchTranslationsOptions>({
    subjectDatabase: true,
    privacySettings: true
  })

  useEffect(() => {
    fetchTranslations()
  }, [filterState])

  const fetchTranslations = () => {
    setIsFetchingTranslations(true)
    fetchTranslationsRequest(filterState, {
      onSuccess: translations => {
        setTranslations(translations)
        setIsFetchingTranslations(false)
      },
      onRequestError: () => {
        DatacMessage.error(intlTranslations('request.error'))
        setIsFetchingTranslations(false)
      }
    })
  }

  const filterSelectionString = [
    filterState.subjectDatabase && intlTranslations('subject_database'),
    filterState.privacySettings && intlTranslations('privacy_settings')
  ]
    .filter(Boolean)
    .join(', ')

  const defaultTranslationIsSet = !!translations?.find(t => t.isDefault && t.locale)

  const onNewLanguageSelected = (locale: string) => {
    addLanguage(
      { locale },
      {
        onSuccess: () => {
          fetchTranslations()
          setIsNewLanguageModalOpened(false)
        },
        onRequestError: () => DatacMessage.error(intlTranslations('request.error'))
      }
    )
  }

  const onDefaultLanguageSelected = (locale: string) => {
    chooseDefaultLanguage(
      { locale },
      {
        onSuccess: () => {
          fetchTranslations()
          setIsDefaultLanguageModalOpened(false)
        },
        onRequestError: () => DatacMessage.error(intlTranslations('request.error'))
      }
    )
  }

  const onDeleteLanguage = (locale: string) => {
    deleteLanguage(
      { locale },
      {
        onSuccess: fetchTranslations,
        onRequestError: () => DatacMessage.error(intlTranslations('request.error'))
      }
    )
  }

  const onChangeTranslation = ({ value, selection, onSuccess, onRequestError }: onChangeTranslationProps) => {
    const original = translations?.find(translation => translation.isDefault)?.translations[selection.key]
    changeTranslation({ translated: value, language: selection.locale, original }, { onSuccess, onRequestError })
  }

  return (
    <SettingsLayout>
      <div className="settings-translations-content">
        <div className="settings-translations-content__header">
          <div className="settings-translations-content__header__right">
            <DatacTitle type="h1">{intlTranslations('global.title')}</DatacTitle>
            <DatacSubtitle type="h2">{intlTranslations('global.description')}</DatacSubtitle>
            {defaultTranslationIsSet && (
              <DatacTitle type="h2" size="small">
                {filterSelectionString}
                <TranslationsFilters setFilterState={setFilterState} />
              </DatacTitle>
            )}
          </div>
          {defaultTranslationIsSet && (
            <DatacIconButton onClick={() => setIsNewLanguageModalOpened(true)}>
              {intlTranslations('add_new')}
            </DatacIconButton>
          )}
        </div>
        <DatacLoading isLoading={isFetchingTranslations}>
          {!defaultTranslationIsSet ? (
            <TranslationsNoResults setIsDefaultLanguageModalOpened={setIsDefaultLanguageModalOpened} />
          ) : (
            <TranslationsLists
              translations={translations}
              onChangeTranslation={onChangeTranslation}
              onDeleteLanguage={onDeleteLanguage}
              changeDefaultLanguage={() => setIsDefaultLanguageModalOpened(true)}
              topPosition={13.5}
            />
          )}
        </DatacLoading>
      </div>
      <TranslationsLanguageSelect
        title={intlTranslations('add_new')}
        currentLocales={translations.map(translation => translation.locale)}
        onSelect={onNewLanguageSelected}
        isOpened={isNewLanguageModalOpened}
        onClose={() => setIsNewLanguageModalOpened(false)}
      />
      <TranslationsLanguageSelect
        title={intlTranslations('set_default')}
        currentLocales={translations.map(translation => translation.locale)}
        onSelect={onDefaultLanguageSelected}
        isOpened={isNewDefaultLanguageModalOpened}
        onClose={() => setIsDefaultLanguageModalOpened(false)}
      />
    </SettingsLayout>
  )
}
